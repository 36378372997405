import SEO from './seo/seo';
import Layout from './layout/layout';
import Footer from './footer/footer';
import Container from './container/container';
import Social from './social/social';
import Header from './header/header';
import About from './about/about';
import Intro from './intro/intro';
import Media from './media/media';
import Slider from './slider/slider';
import Overlay from './overlay/overlay';
import Portal from "./portal/portal";
import Comic from "./comic/comic";
import Features from "./features/features";

export {
  Footer,
  Container,
  Layout,
  SEO,
  Social,
  Header,
  About,
  Intro,
  Media,
  Slider,
  Overlay,
  Portal,
  Comic,
  Features
}