import React from "react"
import PropTypes from "prop-types"

export const ICON_TYPES = {
  'DISCORD': 'DISCORD',
  'YOUTUBE': 'YOUTUBE',
  'FACEBOOK': 'FACEBOOK',
  'TWITTER': 'TWITTER',
  'RSS': 'RSS',
  'STEAM': 'STEAM',
  'HUMBLE': 'HUMBLE',
  'GITHUB': 'GITHUB'
}

const getIconByType = (type) => {
  switch (type) {
    case ICON_TYPES.DISCORD:
      return <path d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"/>;
    case ICON_TYPES.YOUTUBE:
      return <path d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/>;
    case ICON_TYPES.FACEBOOK:
      return <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/>;
    case ICON_TYPES.TWITTER:
      return <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/>;
    case ICON_TYPES.RSS:
      return <path d="M19.199 24C19.199 13.467 10.533 4.8 0 4.8V0c13.165 0 24 10.835 24 24h-4.801zM3.291 17.415c1.814 0 3.293 1.479 3.293 3.295 0 1.813-1.485 3.29-3.301 3.29C1.47 24 0 22.526 0 20.71s1.475-3.294 3.291-3.295zM15.909 24h-4.665c0-6.169-5.075-11.245-11.244-11.245V8.09c8.727 0 15.909 7.184 15.909 15.91z"/>;
    case ICON_TYPES.STEAM:
      return <path d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.878-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012H7.54zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
    case ICON_TYPES.HUMBLE:
      return <path d="M4.145 23.996c.12-.463.23-.9.343-1.338.692-2.713 1.322-5.44 1.82-8.197.245-1.35.446-2.71.633-4.074.142-1.028.217-2.064.198-3.105-.01-.557-.034-1.116-.193-1.655-.07-.24-.174-.473-.3-.686-.165-.273-.43-.378-.75-.368-.883.026-1.633.363-2.272.96-.727.68-1.202 1.527-1.553 2.445-.166.435-.284.887-.422 1.33-.02.066-.026.123-.115.122C1.04 9.424.545 9.425.05 9.424c-.013 0-.024-.008-.036-.01 0-.193-.02-.385.003-.572.346-2.853 1.57-5.267 3.668-7.226C4.47.882 5.4.373 6.462.142 8.017-.196 9.258.4 9.996 1.822c.375.72.578 1.496.71 2.293.21 1.287.218 2.586.175 3.885-.014.42-.04.84-.062 1.26-.002.054 0 .108 0 .176.057.003.105.008.154.008.905 0 1.81-.002 2.717.005.124 0 .16-.047.18-.16.575-3.113 1.367-6.17 2.39-9.166.024-.074.05-.124.147-.124 1.12.004 2.24.004 3.362.004.017 0 .035.004.07.008l-.193.753C18.89 3.7 18.21 6.65 17.66 9.628c-.288 1.546-.533 3.1-.69 4.664-.086.875-.14 1.752-.113 2.63.016.53.054 1.062.22 1.57.064.202.16.4.273.58.167.26.426.366.74.356 1.16-.033 2.042-.59 2.746-1.47.707-.88 1.133-1.9 1.434-2.98.028-.1.06-.202.076-.306.014-.082.054-.104.13-.104.467.002.933.004 1.4 0 .102-.002.12.043.117.13-.014.804-.157 1.583-.39 2.347-.59 1.928-1.557 3.635-2.992 5.06-.813.81-1.762 1.407-2.88 1.706-.677.183-1.355.212-2.025-.028-.76-.27-1.276-.816-1.66-1.504-.402-.725-.613-1.512-.75-2.322-.24-1.406-.24-2.824-.172-4.242.042-.89.127-1.777.193-2.666.014-.19.016-.19-.174-.19-.855 0-1.71.002-2.566-.002-.104 0-.153.024-.17.137-.27 1.813-.637 3.608-1.074 5.387-.453 1.842-.974 3.664-1.587 5.46-.044.127-.104.16-.233.16-1.065-.006-2.13-.004-3.197-.004h-.17z"/>
    case ICON_TYPES.GITHUB:
      return <path d="m12 0c-6.63 0-12 5.37-12 12 0 5.31 3.435 9.795 8.205 11.385 0.6 0.105 0.825-0.255 0.825-0.57 0-0.285-0.015-1.23-0.015-2.235-3.015 0.555-3.795-0.735-4.035-1.41-0.135-0.345-0.72-1.41-1.23-1.695-0.42-0.225-1.02-0.78-0.015-0.795 0.945-0.015 1.62 0.87 1.845 1.23 1.08 1.815 2.805 1.305 3.495 0.99 0.105-0.78 0.42-1.305 0.765-1.605-2.67-0.3-5.46-1.335-5.46-5.925 0-1.305 0.465-2.385 1.23-3.225-0.12-0.3-0.54-1.53 0.12-3.18 0 0 1.005-0.315 3.3 1.23 0.96-0.27 1.98-0.405 3-0.405s2.04 0.135 3 0.405c2.295-1.56 3.3-1.23 3.3-1.23 0.66 1.65 0.24 2.88 0.12 3.18 0.765 0.84 1.23 1.905 1.23 3.225 0 4.605-2.805 5.625-5.475 5.925 0.435 0.375 0.81 1.095 0.81 2.22 0 1.605-0.015 2.895-0.015 3.3 0 0.315 0.225 0.69 0.825 0.57a12.02 12.02 0 0 0 8.175-11.385c0-6.63-5.37-12-12-12z"/>;
    default:
      return null;
  }
}

const Icon = ({ type, className }) => {
  return (
    <svg className={className} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      {
        getIconByType(type)
      }
    </svg>
  )
}

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
}

export default Icon
