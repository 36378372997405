import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import classnames from "classnames"
import { ParallaxBanner } from 'react-scroll-parallax';

import { Container, Slider, Overlay } from "../index"
import styles from "./media.module.scss"
import { mediaType } from "../../types/types"
import character from "./character.png";
import foreground from "./foreground.png"

class Media extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentMedia: 0,
      overlayOpen: false
    };
  }

  onOverlayClose = () => {
    this.setState({
      overlayOpen: false,
    })
  }

  onUpdateCurrent = (index) => {
    this.setState({
      currentMedia: index
    })
  }

  onMediaClick = (index) => {
    this.setState({
      overlayOpen: true,
      currentMedia: index
    })
  }

  render() {
    const { 
      frontmatter: {
        title,
        subTitle,
        downloadLink,
        media,
        pressKit
      }
    } = this.props.data.markdownRemark;
    const { parallaxOn } = this.props;
    const { currentMedia, overlayOpen } = this.state;
    return (
      <section id="media" className={styles.media}>
        <div className={styles.inner}>
          <Container className={styles.container}>
            <header className={styles.header}>
              <div className={styles.title}>
                <h4>{subTitle}</h4>
                <h2 className="h1">{title}</h2>
              </div>
              <div className={styles.pressKit}>
                <p>{pressKit}</p>
                <a 
                  className={classnames("button", styles.download)}
                  href={downloadLink}
                  download
                >Press Page</a>
              </div>
            </header>
            <Slider media={media} onClick={this.onMediaClick} isDesktop={parallaxOn} />
          </Container>
        </div>
        <Overlay
          current={currentMedia}
          media={media}
          visible={overlayOpen}
          onClose={this.onOverlayClose}
          updateCurrent={this.onUpdateCurrent}
        />
        <ParallaxBanner
          className={styles.parallax}
          layers={[
            {
              amount: 0.1,
              expanded: false,
              children: (
                <img className={styles.character} src={character} alt="" />
              ),
            },
            {
              image: foreground,
              amount: -0.2,
              expanded: false,
            },
          ]}
          disabled={!parallaxOn}
        />
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "media" } }) {
          frontmatter {
            title
            subTitle,
            downloadLink,
            pressKit,
            media {
              imgSrc {
                childImageSharp {
                  fluid(maxWidth: 1280, maxHeight: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              youtubeLink
            }
          }
        }
      }
    `}
    render={data => <Media data={data} {...props} />}
  />
)

Media.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        downloadLink: PropTypes.string,
        media: mediaType,
        pressKit: PropTypes.string
      })
    }).isRequired
  }).isRequired,
  parallaxOn: PropTypes.bool,
}
