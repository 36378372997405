import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { ParallaxBanner } from 'react-scroll-parallax';
import classnames from "classnames"
import PropTypes from "prop-types"

import { Overlay } from "../index"
import styles from "./intro.module.scss"
import { baseMarkdownType } from "../../types/types"
import background from "./intro-bg.jpg"
import character from "./character.png"
import foreground from "./foreground.png"
import logo from "./overgrowth-logo.png"

class Intro extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false,
    };
  }

  onOverlayClose = () => {
    this.setState({
      overlayOpen: false,
    })
  }

  onClick = (event) => {
    event.preventDefault()
    this.setState({
      overlayOpen: true,
    })
  }

  render() {
    const { 
      frontmatter: {
        trailerLink,
        subTitle
      }
    } = this.props.data.markdownRemark;
    const { parallaxOn } = this.props;
    const { overlayOpen } = this.state;
    return (
      <section id="home" className={styles.intro}>
        <ParallaxBanner
          className={styles.parallax}
          layers={[
            {
              image: background,
              amount: 0.4,
              expanded: true,
            },
            {
              amount: 0,
              expanded: false,
              children: (
                <div className={styles.container}>
                  <img src={logo} alt="Overgrowth Game Logo" height={195} className={styles.logo} />
                  <div className={styles.buttons}>
                    <div className={styles.buyNow}>
                      <Link
                        to="/buy-now/"
                        className={classnames(styles.buyNowButton, "button")}
                      >
                        Buy now
                      </Link>
                      {subTitle && subTitle}
                    </div>
                    <a 
                      href={trailerLink}
                      className={styles.playTrailer}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => this.onClick(e)}
                    >
                      <span className={styles.playButton}>
                        <span className={styles.playIcon} />
                      </span>
                      Trailer
                    </a>
                  </div>
                </div>
              )
            },
            {
              amount: 0.3,
              expanded: false,
              children: (
                <img className={styles.character} src={character} alt="" />
              ),
            },
            {
              image: foreground,
              amount: -0.2,
              expanded: false,
            },
          ]}
          disabled={!parallaxOn}
        />
        <Overlay
          current={0}
          media={[{
            youtubeLink: trailerLink
          }]}
          visible={overlayOpen}
          onClose={this.onOverlayClose}
        />
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "intro" } }) {
          frontmatter {
            trailerLink
            subTitle
          }
        }
      }
    `}
    render={data => <Intro data={data} {...props} />}
  />
)

Intro.propTypes = {
  ...baseMarkdownType,
  parallaxOn: PropTypes.bool,
}
