import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { ParallaxBanner } from 'react-scroll-parallax'
import PropTypes from "prop-types"

import { Container } from "../index"
import styles from "./features.module.scss"
import { baseMarkdownType } from "../../types/types"
import foreground from "./foreground.png"
import character from "./character.png"

const Features = ({ data, parallaxOn }) => {
  const { 
    html,
    frontmatter: {
      title,
      subTitle,
      features
    }
  } = data.markdownRemark;
  return (
    <section id="features" className={styles.features}>
      <Container className={styles.container}>
        <div className={styles.row}>
          <div className={styles.colLeft}>
            <h4>{subTitle}</h4>
            <h2 className="h1">{title}</h2>
            <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <div className={styles.colRight}>
            <h3>Features:</h3>
            <ul>
              {
                features.map((feature, index) => (
                  <li key={`feature-${index}`}>{feature}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </Container>
      <ParallaxBanner
        className={styles.parallax}
        layers={[
          {
            amount: 0.06,
            expanded: false,
            children: (
              <img className={styles.character} src={character} alt="" />
            ),
          },
          {
            image: foreground,
            amount: -0.2,
            expanded: false,
          },
        ]}
        disabled={!parallaxOn}
      />
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "features" } }) {
          html
          frontmatter {
            title
            subTitle
            features
          }
        }
      }
    `}
    render={data => <Features data={data} {...props} />}
  />
)

Features.propTypes = {
  ...baseMarkdownType,
  parallaxOn: PropTypes.bool,
}
