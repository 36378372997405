import React from "react"

import styles from "./footer.module.scss"
import { Container, Social } from "../index"
import wolfireLogoSrc from "./wolfire-games-logo@2x.png"

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.social}>
      <Container className={styles.container}>
        <h5>Social <br />links:</h5>
        <Social className={styles.icons} />
      </Container>
    </div>
    <footer className={styles.bottom}>
      <Container>
        <nav className={styles.nav}>
          <ul>
            <li className={styles.wolfire}>
              <a href="http://www.wolfire.com" target="_blank" className={styles.wolfireLogo} rel="noopener noreferrer">
                <img src={wolfireLogoSrc} alt="Wolfire Games" width="160" height="54" />
              </a>
              © {new Date().getFullYear()}&nbsp;
              <a href="http://www.wolfire.com" target="_blank" rel="noopener noreferrer">
                Wolfire Games
              </a>
            </li>
            <li><a href="http://blog.wolfire.com/" target="_blank" rel="noopener noreferrer">News</a></li>
            <li><a href="https://www.wolfire.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></li>
            <li className={styles.fullyIllustrated}>
              Site designed by&nbsp;
              <a href="http://www.fullyillustrated.com" target="_blank" rel="noopener noreferrer">
                Fully Illustrated
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  </div>
)

export default Footer
