import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import throttle from "lodash.throttle"

import { Container, Social } from "../index"
import styles from "./header.module.scss"
import logoSrc from "./overgrowth-site-logo@2x.png"
import { isTablet } from "../../utils/responsive"

const SECTIONS = {
  INTRO: {
    name: "home",
  },
  ABOUT: {
    name: "about",
  },
  MEDIA: {
    name: "media",
  },
  FEATURES: {
    name: "features",
  },
  COMIC: {
    name: "comic",
  },
}

const yThreshold = 250;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: null,
      observe: true,
    }
  }

  updateSectionY = () => {
    Object.keys(SECTIONS).forEach((section) => {
      const element = document.getElementById(SECTIONS[section].name);
      const endX = element.offsetTop + element.offsetHeight;
      SECTIONS[section].endY = endX;
    });
  }

  getActiveSection = (yPos) => {
    if(yPos < SECTIONS.INTRO.endY - yThreshold) {
      return SECTIONS.INTRO.name;
    }
    if(yPos < SECTIONS.ABOUT.endY - yThreshold) {
      return SECTIONS.ABOUT.name;
    }
    if(yPos < SECTIONS.MEDIA.endY - yThreshold) {
      return SECTIONS.MEDIA.name;
    }
    if(yPos < SECTIONS.FEATURES.endY - yThreshold) {
      return SECTIONS.FEATURES.name;
    }
    return SECTIONS.COMIC.name;
  }

  handleClick = (event, id) => {
    if(this.props.isHomepage) {
      event.preventDefault();
      const element = document.getElementById(id);
      element.scrollIntoView({behavior:"smooth", block: "start"});
    }
    this.setState({
      activeSection: id,
      observe: false,
    });
    clearTimeout(this.observeTimer);
    this.observeTimer = setTimeout(() => {
      this.setState({observe: true});
    }, 850);
  }

  handleScroll = () => {
    if(!this.state.observe) return;
    const { scrollY } = window;
    const activeSection = this.getActiveSection(scrollY);
    if(activeSection !== this.state.activeSection) {
      this.setState({
        activeSection
      })
    }
  }

  throttleScroll = throttle(this.handleScroll, 250);

  componentWillUnmount() {
    clearTimeout(this.observeTimer);
    window.removeEventListener('scroll', this.throttleScroll, false);
  }

  componentDidMount() {
    if(!window || !this.props.isHomepage || isTablet()) return;
    window.addEventListener('scroll', this.throttleScroll, false);
    this.updateSectionY();
    this.handleScroll();
  }

  render() { 
    const { activeSection } = this.state;
    return (
      <header className={styles.header}>
        <Container className={styles.container}>
          <Link to="/" className={styles.logo}>
            <img src={logoSrc} alt="Overgrowth logo" />
          </Link>
          <nav className={styles.nav}>
            <ul className={styles.links}>
              {
                Object.keys(SECTIONS).map((section, index) => (
                  <li key={`section_${index}`}>
                    <Link
                      to={`/#${SECTIONS[section].name}`}
                      onClick={(e) => this.handleClick(e, SECTIONS[section].name)}
                      className={classnames({
                        [styles.active]: activeSection === SECTIONS[section].name
                      })}
                    >
                      {SECTIONS[section].name}
                    </Link>
                  </li>
                ))
              }
            </ul>
            <Social className={styles.social} />
            <div>
              <Link to="/buy-now/" className={classnames(styles.buyNow, "button")}>Buy now</Link>
            </div>
          </nav>
        </Container>
      </header>
    )
  }
}

Header.propTypes = {
  isHomepage: PropTypes.bool
}

Header.defaultProps = {
  isHomepage: false
}

export default Header
