const TABLET_MAX_WIDTH = 1079;

export const isDesktop = () => {
  if (typeof window !== "undefined") {
    if (window.innerWidth > TABLET_MAX_WIDTH) {
      return true;
    }
  }
  return false;
}

export const isTablet = () => {
  if (typeof window !== "undefined") {
    if (window.innerWidth <= TABLET_MAX_WIDTH) {
      return true;
    }
  }
  return false;
}
