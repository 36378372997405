import React from "react"
import { graphql, StaticQuery } from "gatsby"
import classnames from "classnames"

import { Container } from "../index"
import styles from "./comic.module.scss"
import { baseMarkdownType } from "../../types/types"
import character from "./character.png"

const Comic = ({ data }) => {
  const { 
    html,
    frontmatter: {
      title,
      subTitle,
      comicLink
    }
  } = data.markdownRemark
  return (
    <section id="comic" className={styles.comic}>
      <Container className={styles.container}>
        <div className={styles.inner}>
          <h4>{subTitle}</h4>
          <h2 className="h1">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <a 
            className={classnames("button", styles.comicLink)}
            href={comicLink}
          >Check it out</a>
        </div>
        <div className={styles.character} >
          <img src={character} alt="" className={styles.characterImg} />
        </div>
      </Container>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "comic" } }) {
          html
          frontmatter {
            title
            subTitle
            comicLink
          }
        }
      }
    `}
    render={data => <Comic data={data} {...props} />}
  />
)

Comic.propTypes = {
  ...baseMarkdownType
}
