import PropTypes from "prop-types"

export const baseMarkdownType = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        subTitle: PropTypes.string,
        downloadLink: PropTypes.string
      })
    }).isRequired
  }).isRequired
}

export const childImageSharpType = PropTypes.shape({
  fluid: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    aspectRatio: PropTypes.number
  })
})

export const mediaType = PropTypes.arrayOf(PropTypes.shape({
  imgSrc: PropTypes.shape({
    childImageSharp: childImageSharpType,
  }),
  youtubeLink: PropTypes.string
}))
