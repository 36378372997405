import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import Icon, { ICON_TYPES } from "./icon"
import styles from "./social.module.scss"

const Social = ({
  className
}) => {
  return (
    <ul className={classnames(styles.social, className)}>
      <li>
        <a href="https://www.facebook.com/wolfiregames/" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.FACEBOOK} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/Wolfire" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.TWITTER} />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCGFhIp3VXy90--3PfAqtSaA" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.YOUTUBE} />
        </a>
      </li>
      <li>
        <a href="https://store.steampowered.com/app/25000/Overgrowth/" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.STEAM} />
        </a>
      </li>
      <li>
        <a href="https://www.humblebundle.com/store/overgrowth" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.HUMBLE} />
        </a>
      </li>
      <li>
        <a href="https://www.github.com/WolfireGames/overgrowth" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.GITHUB} />
        </a>
      </li>
      <li>
        <a href="https://feeds.feedburner.com/WolfireGames" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.RSS} />
        </a>
      </li>
    </ul>
  )
}

Social.propTypes = {
  className: PropTypes.string,
}

export default Social
