import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { ParallaxBanner } from 'react-scroll-parallax';
import { Container } from "../index"
import styles from "./about.module.scss"
import { baseMarkdownType } from "../../types/types"
import character from "./character.png"
import foreground from "./foreground.png"

const About = ({ data, parallaxOn }) => {
  const { 
    html,
    frontmatter: {
      title,
      subTitle
    }
  } = data.markdownRemark;
  return (
    <section id="about" className={styles.about}>
      <div className={styles.inner}>
        <Container className={styles.container}>
          <h4>{subTitle}</h4>
          <h2 className="h1">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
        <img className={styles.characterImg} src={character} alt="" />  
      </div>      
      <ParallaxBanner
        className={styles.parallax}
        layers={[
          {
            image: foreground,
            amount: -0.2,
            expanded: false,
          },
        ]}
        disabled={!parallaxOn}
      />
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "about" } }) {
          html
          frontmatter {
            title
            subTitle
          }
        }
      }
    `}
    render={data => <About data={data} {...props} />}
  />
)

About.propTypes = {
  ...baseMarkdownType,
  parallaxOn: PropTypes.bool,
}
