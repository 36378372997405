import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./layout.module.scss"
import { Header, Footer } from "../index"

const Layout = ({ children, className, contentPage = true }) => {
  return (
    <>
      <div className={classnames(styles.layout, {
          [styles.contentPage]: contentPage
        }, className)}>
        <Header isHomepage={!contentPage}/>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
