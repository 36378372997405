import React from "react"
import Img from "gatsby-image"
import classnames from "classnames"
import PropTypes from "prop-types"

import styles from "./slider.module.scss"
import { mediaType } from "../../types/types"
import chunkArray from "../../utils/chunkArray"

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.mediaSets = chunkArray(props.media, 2);
    this.totalSets = this.mediaSets.length;
    const setsInView = this.props.isDesktop ? 3 : 2;

    this.state = {
      page: 1,
      setsInView,
      totalPages: Math.ceil(this.totalSets / setsInView),
    };
  }

  componentDidUpdate(prevProps){ 
    const { isDesktop } = this.props;
    if(isDesktop !== prevProps.isDesktop){
      const setsInView = isDesktop ? 3 : 2;
      this.setState({
        setsInView
      })
    }
  }

  toPage = (page) => {
    this.setState({ 
      page,
    })
  }

  onClick = (event, imgSrc) => {
    event.preventDefault();
    const index = this.props.media.findIndex((e) => imgSrc === e.imgSrc.childImageSharp.fluid.src)
    this.props.onClick(index);
  }

  createPips = () => {
    const { page, totalPages } = this.state;
    const items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(<li key={`pip-${i}`}>
        <button
          onClick={() => this.toPage(i)}
          type="button"
          className={classnames(styles.pipBtn, {
            [styles.activePip]: page === i
          })}
        >
          <span className={styles.pip} />
        </button>
      </li>)
    }
    return (
      <ul className={styles.pips}>
        {items}
      </ul>
    )
  }

  render() {
    const { page } = this.state;
    const xPos = (page - 1) * 100;
    return (
      <>
        <div className={styles.slider}>
          <div className={styles.mask}>
            <ul
              className={styles.track}
              style={{
                transform: `translateX(-${xPos}%)` 
              }}
            >
              {
                this.mediaSets.map((set, index) => (
                  <li className={styles.set} key={`set_${index}`}>
                    {
                      set.map((item, index) => {
                        const { fluid } = item.imgSrc.childImageSharp;
                        return(
                          <a
                            href={item.youtubeLink ? item.youtubeLink : fluid.src}
                            className={styles.item}
                            key={`media_${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => this.onClick(e, fluid.src)}
                          >
                            <Img 
                              className={styles.img}
                              fluid={fluid}
                              alt=""
                            />
                            { 
                              item.youtubeLink && <span className={styles.videoBtn} />
                            }
                          </a>
                        )
                    })
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        {this.createPips()}
      </>
    )
  }
}

Slider.propTypes = {
  media: mediaType,
  onClick: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool,
}

export default Slider
